<template>
  <h1>Betalingen</h1>

  <div class="py-4 inline-flex flex-col">
    <h2 class="bg-dashboard-dark text-white mb-0 px-2 py-1">Filter</h2>
    <div class="flex flex-row gap-4 px-2 mt-2">
      <UIInputDatePicker
        label="Betaal datum"
        class="text-left"
        clearable
        v-model:date="filters.date_betaald"
        @update:date="filters.date_terugbetaald = null"
      />
      <UIInputDatePicker
        label="Datum terugbetaling"
        class="text-left"
        clearable
        v-model:date="filters.date_terugbetaald"
        @update:date="filters.date_betaald = null"
      />
    </div>
  </div>

  <div class="clear-both"></div>

  <UITableResponsive :items="list" class="text-center" :suffix-headers="['Acties']">
    <template v-slot:item-rit="{ item }">
      {{ item.rit.id }}
    </template>
    <template v-slot:item-betalingen="{ item }">
      <div class="grid">
        <div>
          <span class="block" v-if="typeof item.betalingen.CASH !== 'undefined'">
            <b>CASH</b>: &euro;&nbsp;{{ formatPrice(item.betalingen.CASH) }}
          </span>
          <span class="block" v-if="typeof item.betalingen.BETAALTERMINAL !== 'undefined'">
            <b>BETAALTERMINAL</b>: &euro;&nbsp;{{ formatPrice(item.betalingen.BETAALTERMINAL) }}
          </span>
          <span class="block" v-if="typeof item.betalingen.OVERSCHRIJVING !== 'undefined'">
            <b>OVERSCHRIJVING</b>: &euro;&nbsp;{{ formatPrice(item.betalingen.OVERSCHRIJVING) }}
          </span>
          <span class="block" v-if="typeof item.betalingen.FACTUUR !== 'undefined'">
            <b>VIA FACTUUR</b>: &euro;&nbsp;{{ formatPrice(item.betalingen.FACTUUR) }}
          </span>
        </div>
        <div>
          <span class="block" v-if="typeof item.betalingen.TOTAAL !== 'undefined'">
            <b>TOTAAL BETAALD</b>: &euro;&nbsp;{{ formatPrice(item.betalingen.TOTAAL) }}
          </span>
          <span class="block" v-if="typeof item.betalingen.TERUGBETAALD !== 'undefined'">
            <b>TOTAAL TERUGBETAALD</b>: &euro;&nbsp;{{ formatPrice(item.betalingen.TERUGBETAALD) }}
          </span>
        </div>
      </div>
    </template>
    <template v-slot:item-adressen_heen="{ item }">
      <GoogleLink
        v-for="(adres, index) in item.adressen_heen"
        :key="adres.adres + index + item.rit.id"
        :icon="adres.is_luchthaven ? 'fas fa-plane mr-1' : 'fas fa-map-marker-alt mr-1'"
        :adres="adres.adres"
        class="block text-base pt-1 mr-auto truncate max-w-lg"
      />
    </template>
    <template v-slot:item-adressen_retour="{ item }">
      <span v-if="!item.rit.is_retour" class="text-red-500"> Enkele rit! </span>
      <template v-else>
        <GoogleLink
          v-for="(adres, index) in item.adressen_retour"
          :key="adres.adres + index + item.rit.id"
          :icon="adres.is_luchthaven ? 'fas fa-plane mr-1' : 'fas fa-map-marker-alt mr-1'"
          :adres="adres.adres"
          class="block text-base pt-1 mr-auto truncate max-w-lg"
        />
      </template>
    </template>
    <template v-slot:item-Acties="{ item }">
      <router-link :to="`/ritten/info/${item.rit.id}`" class="mr-2 btn info small">
        <i class="fas fa-car"></i>
        Bekijk rit
      </router-link>
    </template>
  </UITableResponsive>
  <UITablePagination class="mt-2" :count="count" v-model:limit="filters.limit" v-model:offset="filters.offset" />
</template>

<script setup>
import { watch, ref, inject, onMounted, defineAsyncComponent } from 'vue'
import { isDate, dateString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import UITableResponsive from '@/components/UI/Table/Responsive'

import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import { useRoute } from 'vue-router'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))
const GoogleLink = defineAsyncComponent(() => import('@/components/Google/Link.vue'))

const axios = inject('axios')

const list = ref([])
const count = ref(0)
const route = useRoute()

const date_terugbetaald = isDate(route.query.date_terugbetaald) ? dateString(route.query.date_terugbetaald) : ''
const date_betaald = dateString(isDate(route.query.date) ? route.query.date : new Date())

const filters = ref({
  date_betaald: date_terugbetaald ? '' : date_betaald,
  date_terugbetaald,
  limit: 20,
  offset: 0,
})

if (filters.value.date_terugbetaald) {
  filters.value.date_betaald = null
}

watch(filters.value, () => {
  list.value = []
  count.value = 0
  getData(null, true)
})

onMounted(() => getData(null, true))

const getData = async (_params = null, setVm = true) => {
  const params = _params || filters.value
  const { data } = await axios.get('/api/dashboard/betalingen/list', { params })
  if (setVm && data.list) list.value = data.list
  if (setVm && data.count) count.value = data.count
  return data
}
</script>

<style lang="scss" scoped>
ul {
  border-collapse: collapse;
  @apply border border-dashboard-dark;
}
ul li {
  @apply border border-dashboard-dark;
  @apply px-2 py-1 pr-4;
  border-collapse: collapse;
}
</style>
